
$.fn.preregister = function(options){
    var defaults = {
        obj: $(this),
        elStage: {
            bg: '.stage_bg',
            wrapper: '.stage_wrapper',
            form: '.stagePreregister',
            success: '.stageSuccess',
            fail: '.stageFail'
        },
        elSubmit: '#submit',
        elRetry: '#retry',
        elTotal: '#total',
        elError: '.form_control__error',
        elCheatMultiple: '#preregisterMultiple',
        elCheatAdd: '#preregisterAdd',
        elLuckyNumber: '#luckyNumber',
        elMileStones: '.milestones__bar__active',
        elMileStonesMobile: '.milestones__bar__active--point',
        elRewards: '.milestones__rewards',
        percentage: {
            milestones: [10000, 50000, 100000, 300000, 500000, 1000000],
            ajustDesktop: [6, 17, 28.5, 39.5, 51, 67],
            ajustMobile: [153, 338, 507, 676, 847, 1000],
            ajustMobilePoint: [103, 272, 441, 612, 783, 954]
        },
        validateList: [
            {
                el: '#phoneNumber',
                type: 'phone'
            },
            {
                el: '#email',
                type: 'email'
            },
            {
                el: '#country',
                type: 'country'
            }
        ]
    }
    var settings = $.extend(defaults, options);

    settings.obj.find(settings.elSubmit).bind("click", function(e){
        e.preventDefault();

        // var validateResult = (validateEmail(settings.obj.find("#email").val())) && (validatePhone(settings.obj.find("#phone").val()));

        const validateResult = function(){
            let isValidated = true;
            settings.validateList.forEach(function(item) {
                let val = settings.obj.find(item.el).val();

                // let val
                // console.log(val);
                switch (item.type) {
                    case 'phone': 
                        let validateResultPhone = validatePhPhone(val);
                        if (!validateResultPhone) {
                            $(item.el).next(settings.elError).show();
                            isValidated = false;
                            return;
                        }
                        break;
                    case 'email': 
                        let validateResultEmail = validateEmail(val);
                        if (!validateResultEmail) {
                            $(item.el).next(settings.elError).show();
                            isValidated = false;
                            return;
                        }
                        break;
                    default: 
                        break;
                }
            });
            return isValidated;
        }

        // validateResult();
        // console.log(validateResult());

        if (validateResult()) {
            var dataPost = settings.obj.find(settings.elStage.form).find("form").serialize();
            var urlPost = domain + '/' + "pre-register.json";
            console.log(dataPost);
            
            $.ajax({
                url: urlPost,
                dataType: 'json',
                method: 'POST',
                data: dataPost,
                success: function(data) {
                    // console.log(data);
                    if (data.regSuccess != 1) {
                        // $(settings.elStage.form).css({display: 'none'});
                        grecaptcha.reset();
                        $("#g-recaptcha").next(settings.elError).show();
                        $(settings.elStage.bg).removeClass("active");
                        $(settings.elStage.wrapper).removeClass("active");
                        $(settings.elStage.fail).addClass('active');
                    } else {
                        // $(settings.elStage.form).css({display: 'none'});
                        $(settings.elStage.bg).removeClass("active");
                        $(settings.elStage.wrapper).removeClass("active");
                        $(settings.elStage.success).addClass('active');
                        $("#registeredEmail").text(settings.obj.find("#email").val());
                        $(settings.elLuckyNumber).text(data.eData.luckyNumber);
                    }
                }
            })
        } else {
            // settings.obj.find(settings.elStage.form).css({display: 'none'});
            $(settings.elStage.bg).removeClass("active");
            $(settings.elStage.wrapper).removeClass("active");
            $(settings.elStage.fail).addClass('active');
        }

    });

    settings.obj.find(settings.elRetry).bind("click", function(e){
        // settings.obj.find(settings.elStage.form).css({display: 'block'});
        $(settings.elStage.bg).removeClass("active");
        $(settings.elStage.wrapper).removeClass("active");
        $(settings.elStage.form).addClass('active');
    });

    if ($(settings.elTotal).length > 0) {
        var urlGet = domain + '/count-pre-register/' + settings.obj.find('input[name="data[event]"]').val() + ".json";
        $.ajax({
            url : urlGet,
            dataType : 'json', 
            method : 'POST',
            data : {},
            success: function (data) {
    
                var total = parseInt(data.total);

                if ($(settings.elCheatMultiple).length > 0) {
                    total *= parseInt($(settings.elCheatMultiple).val()); 
                }

                if ($(settings.elCheatAdd).length > 0) {
                    total += parseInt($(settings.elCheatAdd).val()); 
                }

                console.log(total);

                $(settings.elTotal).text(numberWithCommas(total));
                

                if ($(settings.elMileStones).length > 0) {

                    var device = {
                        width: $("body").outerWidth(),
                        height: $("body").outerHeight()
                    };

                    if (device.width > 841) { 
                        $(settings.elMileStones).css({
                            width: "0%"
                        })
                        for (var i = 0, len = settings.percentage.milestones.length; i < len; i++) {
                            if (total >= settings.percentage.milestones[i]) {
                                $(settings.elMileStones).css({
                                    width: settings.percentage.ajustDesktop[i] + "%"
                                })

                                $(settings.elRewards).find("li").eq(i).addClass("active");
                            }
                        }
                    } else {
                        $(settings.elMileStones).css({
                            height: "0px"
                        })
                        $(settings.elMileStonesMobile).css({
                            height: "0px"
                        })
                        for (var i = 0, len = settings.percentage.milestones.length; i < len; i++) {
                            if (total >= settings.percentage.milestones[i]) {
                                $(settings.elMileStones).css({
                                    height: settings.percentage.ajustMobile[i] + "px"
                                })

                                $(settings.elRewards).find("li").eq(i).addClass("active");
                            }
                        }
                        for (var i = 0, len = settings.percentage.milestones.length; i < len; i++) {
                            if (total >= settings.percentage.milestones[i]) {
                                $(settings.elMileStonesMobile).css({
                                    height: settings.percentage.ajustMobilePoint[i] + "px"
                                })
                            }
                        }
                    }
                }
            }
        });
    }

};

const validateEmail = function(email) {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return ((emailReg.test( email )) && (email.length > 4));
}
const validatePhone = function(phone) {
    var phoneReg = /^\d*$/;
    return ((phoneReg.test(phone)) && (phone.length == 10));
}
const validatePhPhone = function(phone) {
    var phoneReg = /(639\d{9})|(09(04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31|32|33|34|35|36|38|39|40|41|42|43|44|45|46|47|48|49|50|51|53|54|55|56|61|63|65|66|67|68|70|73|74|75|76|77|78|79|81|89|91|92|92|93|94|94|95|96|97|98|99)\d{7})$/;
    return ((phoneReg.test(phone)));
}
const numberWithCommas = function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// function validateEmail(email) {
// }

// function validatePhone(phone) {
// }

// function numberWithCommas(x) {
// }