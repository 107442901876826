import config from "../../setup/config";

import "../../setup/js/_toggleClass";
import "../../setup/js/_scaleRoot.2";
// import "../../setup/js/_lightbox";
import "@fancyapps/fancybox";
import "../../setup/js/_swiper";
import "../../setup/js/_universalLink";
import "../../setup/js/_scrollwatch";
// import "../../setup/js/_language";
// import "../../setup/js/_audioController";
// import "../../setup/js/_smoothScrollbar";
// import "../../setup/js/_wiki";
import "../../setup/js/_inertiaAside";
import scrollFrame from "../../setup/js/_scrollFrame";
import "lazysizes";
import List from "list.js";
import { dndPromotion, getAwardSrcById } from "../../setup/js/_promotion";
import { milestonesRendered } from "../../setup/js/_milestones";
import "@fancyapps/fancybox";
import Choices from "choices.js";
import preregister from "../../setup/js/_preregister";

$(function () {
  commonLibs();

  if ($("#floatright").length > 0) {
    $("#floatright .floatright__inertia").inertiaAside();
  }

  // INIT SWIPER

  if ($("#swiperFeature").length > 0) {
    var slideTotal = 0,
      slideCurrent = 0;
    $("#swiperFeature").initSwiper({
      isRenderBullet: true,
      swiperOptions: {
        slidesPerView: "auto",
        centeredSlides: true,
        loop: true,
        //spaceBetween: 0,
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0,
          //   stretch: 438,
          stretch: 270,
          depth: 323,
          modifier: 1,
          slideShadows: true,
        },
        lazy: {
          loadPrevNext: true,
        },
        // autoplay: {
        //   delay: 5000,
        // },
        breakpoints: {
          769: {
            slidesPerView: "auto",
            effect: "coverflow",
            coverflowEffect: {
              stretch: 438,
              depth: 323,
            },
          },
        },
      },
    });
  }
});

window.addEventListener("load", function () {
  //When load all assets (style, images,...)

  $(window)
    .on("resize", function () {
      $("#wrapper").scalePlatform();
    })
    .resize();

  // this.setTimeout(function(){
  //     $(window).on("resize", function(){
  //         $("#wrapper").scalePlatform();
  //     }).resize();
  // }, 1000);

  // Add global functions here
  $("#loading").each(function () {
    $("#loading").removeClass("active");
  });
});

var commonLibs = function () {
  $("#wrapper").scalePlatform();

  $(".onelink").each(function () {
    $(".onelink").universalLink();
  });

  $("#navBurger").each(function () {
    $("#navBurger").toggleClassname({
      toggle: [
        {
          el: $("#floattop"),
          className: "active",
        },
        {
          el: $("#floatnav"),
          className: "active",
        },
        {
          el: $("#navBurger"),
          className: "is-active",
        },
        {
          el: $("#navBurger"),
          className: "active",
        },
      ],
    });
  });

  // $("#asideRightToggle").each(function () {
  //     $(this).toggleClassname({
  //         toggle: [
  //             {
  //                 el: $(".floatright__main"),
  //                 className: 'active'
  //             }
  //         ]
  //     });
  // });

  // $(".scrollContent").smoothScrollbar();

  // $('[data-fancybox="news_item"').fancybox({
  //     toolbar: false,
  //     afterShow: function(instance, current){

  //         var $currentPopup = current.$content;

  //         $currentPopup.append(`
  //             <div data-fancybox-close class="fancybox-button fancybox-button--close">
  //                 <img class="lazyload" data-src="//global-mainsite.mto.zing.vn/products/hoc/skin-2022/mainsite/assets/images/module/btn-close.png" />
  //             </div>
  //         `)
  //     }
  // })

  // $(".scrollTop").each(function () {
  //     $(this).on("click", function () {
  //         $("html, body").animate({ scrollTop: 0 }, 400)
  //     })
  // })

  var scrollWatchList = [
    "#homeHeader",
    "#homeChar",
    "#homeInfo",
    "#homeFeature",
    "#commonSocial",
  ];
  scrollWatchList.forEach(function (scrollWatchItem) {
    if ($(scrollWatchItem).length > 0) {
      $().scrollWatch({
        options: {
          watchOnce: false,
          watch: scrollWatchItem + "-scrollwatch-pin",
          scrollThrottle: 20,
          onElementInView: function () {
            $(".floatnav__item").removeClass("active");
            $('.floatnav__item[href="' + scrollWatchItem + '"]').addClass(
              "active"
            );
          },
          onElementOutOfView: function () {
            // $('.floatnav__item').removeClass("active");
            $('.floatnav__item[href="' + scrollWatchItem + '"]').removeClass(
              "active"
            );
          },
        },
      });
    }
  });

  scrollFrame.init();
};
